import Vue from 'vue'
import App from './App.vue'
import VueLazyload from 'vue-lazyload'
import InfiniteLoading from 'vue-infinite-loading';
import VueZoomer from './components/vue-zoomer.vue'
import VueZoomerGallery from './components/vue-zoomer-gallery.vue'
import { VueMasonryPlugin } from "vue-masonry";
import Amplify from 'aws-amplify';
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import config from '../../config.json'

Amplify.configure({
  Auth:{
    mandatorySignId:true,
    region:config.Region,
    userPoolId:config.ClientsUserPoolId,
    userPoolWebClientId:config.UserPoolClientId,
    identityPoolId:config.IdentityPoolId
  }
})
Vue.config.productionTip = false

Vue.component('VZoomer', VueZoomer)
Vue.component('VZoomerGallery', VueZoomerGallery)
Vue.use(VueMasonryPlugin);
Vue.use(InfiniteLoading)

Vue.use(VueLazyload, {
  lazyComponent: true,
  error: '/templates/photo/no-logo.png',
  loading: '/templates/photo/loading.gif',
});


store.dispatch('getSettings')
//eslint-disable-next-line
.then((response)=>{
  return store.dispatch('getDict')
})
//eslint-disable-next-line
.then((response)=>{
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})




import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
import {Auth} from 'aws-amplify'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // single source of data
        files: [],
        path: '/',
        user: {},
        noPassLogin:false,
        hideUser:false,
        // TODO replace dict with language files
        dict : {}, 
        sortKey: 'name',
        sortDirAsc: true,
        jwt: {
            'token': localStorage.getItem('token')
        },
        settings: {},
        onlyFolder:false,
        title:""
    },
    getters: {
        // reusable data accessors
        isAuthenticated(state) {
            if (!state.jwt.token || state.jwt.token.split('.').length < 3) {
                return false
            }
            const data = JSON.parse(atob(state.jwt.token.split('.')[1]))
            const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
            const now = new Date()
            return now < exp
        }
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setJwtToken(state, payload) {
            localStorage.token = payload.token
            state.jwt = payload
        },
        setPath(state, payload) {
            state.path = payload.path
        },
        setFiles(state, payload) {
            state.files = payload.files
        },
        setSortKey(state, key) {
            state.sortKey = key
        },
        setSortDirAsc(state, dirAsc) {
            state.sortDirAsc = dirAsc
        },
        setOnlyFolder(state, onlyFolder) {
            state.onlyFolder=onlyFolder
        },
        setTitle(state, title) {
            state.title=title
        }
    },
    actions: {
        listDir(context, folder) {
            return axios.post("/list",{
                "path":(folder+"/").replace("//","/"),
                "type":'dir'
                },{
                headers: {
                    "Authorization":context.state.jwt.token
                }
            })
            .then(response=>{
                context.commit("setFiles",response.data)
                context.commit('setPath', {'path': folder.replace('//', '/')})
                if(response.data.files.length == 1 && response.data.files[0].type=='dir'){
                    context.commit('setOnlyFolder',true)
                    router.push((folder+"/"+response.data.files[0].name).replace("//","/"))
                }
                return true
            })
            .catch(error=>{
                console.log(error)
                return false
            })
        },
        reloadDir(context, folder) {
            return context.dispatch('listDir',folder)
        },
        getFile(context,file){
            axios.get("/list",{
                headers: {
                    "Authorization":context.state.jwt.token
                },
                params:{
                    "path":file,
                    "type":'file'
                }      
            }).then(response => {
                const filename = response.headers['content-disposition'].split('=')[1].split("'").pop()
                let link = document.createElement('a');
                link.href=response.data
                link.download = filename;
                document.body.appendChild(link);
                link.click()
                document.body.removeChild(link);
            }).catch(error=>{
                console.log(error)
                return false
            })

        },

        //eslint-disable-next-line
        getUrl(context,file){
            //eslint-disable-next-line
            return new Promise(function(resolve, reject) {
                axios.get("/list",{
                    headers: {
                        "Authorization":context.state.jwt.token
                    },
                    params:{
                        "path":file,
                    }                   
                }).then(response=>{
                    resolve(response.data)
                })
            });
        },

        getTitle(context){
            context.dispatch('getUrl','/title.txt')
            .then(response=>{
                console.log(response)
                axios.get(response)
                .then(response=>{
                    context.commit('setTitle',response.data)
                })
                .catch((error)=>{
                    console.log(error)
                    context.commit('setTitle',context.state.dict['default_title'])
                })

            })
        },

        logout(context) {
            Auth.signOut()
            context.commit('setJwtToken', {
                'jwt': {
                    'token': ''
                }
            })
        },
        getDict(context){
            var defaultDict,defaultCustom,browserDict,browserCustom
            var lang = (navigator.language || navigator.userLanguage).split("-")[0] 

            //loading default languages first (template and user custom)
            return context.dispatch('getSystemFile','templates/photo/defaults-all.json')
            .then((response)=>{
                defaultDict = response
                return context.dispatch('getSystemFile','data-'+context.state.settings['site_language']+'.json')

            })
            //override with browser language if present
            .then((response)=>{
                defaultCustom = response
                return context.dispatch('getSystemFile','templates/photo/defaults-'+lang+'.json')
            })
            .then((response)=>{
                browserDict = response
                return context.dispatch('getSystemFile','data-'+lang+'.json')
            })
            .then((response)=>{
                browserCustom = response
                context.state.dict = Object.assign({},defaultDict,defaultCustom,browserDict,browserCustom)
                return response
            })
        },
        getSettings(context){
            return context.dispatch('getSystemFile','settings.json')
            .then((response)=>{
                context.state.settings = response
                context.state.noPassLogin = response['loginUserOnly']
                context.state.hideUser = response['loginUserHide']
                return response
            })
        },
        getSystemFile(context,file){
            return axios.get("/"+file)
            .then(response => {
                return response.data
            })
            .catch(() => {
                return {}
            })
        }
    }
});

import { render, staticRenderFns } from "./vue-zoomer-gallery.vue?vue&type=template&id=1aee5453&scoped=true&"
import script from "./vue-zoomer-gallery.vue?vue&type=script&lang=js&"
export * from "./vue-zoomer-gallery.vue?vue&type=script&lang=js&"
import style0 from "./vue-zoomer-gallery.vue?vue&type=style&index=0&id=1aee5453&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aee5453",
  null
  
)

export default component.exports
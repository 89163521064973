<template v-slot:activator="{on}">
  <v-main style="background-color:#ffffff; padding-bottom:60px">
    <v-sheet id="top_bar" :dark="$store.state.settings.dark" color="primary" class="py-10">
      <v-container style="position:relative;">
        <v-btn 
          icon 
          @click="$router.push(prevFolder.path)" 
          v-if="($store.state.onlyFolder && pathItems.length > 2) || (!$store.state.onlyFolder && pathItems.length > 1)"
          style="position:absolute;top:10px;left:10px;z-index:800"  
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
          <center><v-img class="my-6" src="/assets/logo.png" alt="logo" height="200px" contain ></v-img> </center>
          <center><h1>{{$store.state.title}}</h1></center>
          <center v-if="($store.state.onlyFolder && pathItems.length > 2) || (!$store.state.onlyFolder && pathItems.length > 1)">
            <h3>{{currFolder.name}}</h3>
          </center>

         <v-tooltip bottom  >
          <template v-slot:activator="{on}">
            <v-btn style="position:absolute;top:10px;right:10px;z-index:800" large icon v-on="on" @click="showExitDialog = true">
              <v-icon>exit_to_app</v-icon>
            </v-btn>
          </template>
          <span>{{dict['exit_confirm']}}</span>
        </v-tooltip>
      </v-container>
    </v-sheet>
    <v-container>
      <v-card text class="mt-md-5 px-3" v-if="!loading" elevation=0>
        <v-row>
          <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
          <v-col
            v-for="(item,index) in items"
            :key="index"
            cols="6"
            md="4"
            lg="3"
            @click="openItem(item,index)"
            class="text-center pa-1"
            v-if="item.type!='dir'"
            style="overflow: hidden"
          >
            <img v-lazy="getUrl(item)" style="max-height:200px;max-width:100%" @contextmenu.prevent>
          </v-col>
          <v-col
            cols="6"
            lg="3"
            @click="openItem(item,index)"
            min-height="150"
            class="text-center"
            v-else-if="onlyFolders"
          >
            <div v-lazy:background-image="getUrl(item)" style="background-size:contain;background-position:center;width:100%;height:200px;" @contextmenu.prevent></div>
            <p class=" mt-2 text-center text-subtitle-1">{{item.name}}</p>
          </v-col> 
        </v-row>
        <infinite-loading @infinite="scrollHandler">
          <span slot="no-more"></span>
          <span slot="no-results"></span>
        </infinite-loading>
      </v-card>
      <v-card class="transparent text-center pa-5" text v-if="loading" elevation="0">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-card>
    </v-container>
    <v-dialog v-model="showExitDialog" max-width="290">
      <v-card>
        <v-card-title>{{dict['exit_text']}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showExitDialog = false">{{dict['cancel']}}</v-btn>
          <v-btn text @click="logout" color="primary">{{dict['exit_confirm']}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="imageDialog"
      fullscreen
      hide-overlay
      style="z-index:900;"
      @keydown.left="if(dialogStart>0) dialogStart-=1"
      @keydown.right="if(dialogStart < $store.state.files.length) dialogStart+=1"
    >
      <v-card color="#000">
        <v-card-title class="pa-0">
          <v-btn
            icon
            x-large
            dark
            @click="imageDialog = false"
            style="position:absolute;right:10px;top:10px;z-index:999"
          >
            <v-icon x-large>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 position-relative">
          
          <v-zoomer-gallery
            :list="images"
            :captions="captions"
            v-model="dialogStart"
            :style="dialogStyle"
          >
          </v-zoomer-gallery>
          <div class="controls d-xs-none d-sm-none d-md-block">
            <v-btn icon x-large style="position:absolute;top:0;bottom:0;left:10px;z-index:999;margin:auto" color="#fff" v-if="dialogStart > 0" @click="dialogStart=dialogStart-1">
              <v-icon>navigate_before</v-icon>
            </v-btn>
            <v-btn icon x-large style="position:absolute;top:0;bottom:0;right:10px;z-index:999;margin:auto" color="#fff"  v-if="dialogStart < $store.state.files.length" @click="dialogStart=dialogStart+1">
              <v-icon>keyboard_arrow_right</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    <v-dialog>
  </v-main>
</template>

<script>

import moment from 'moment'

export default({
  name:"Files",
  data: function() {
    return {
      loading: true,
      searchText: "",
      dict: this.$store.state.dict,
      sorters: {
        show: false,
        types: [
          { label: this.$store.state.dict['order_by_name'], icon: "sort_by_alpha", key: "name" },
          { label: this.$store.state.dict['order_by_date'], icon: "access_time", key: "date" },
          { label: this.$store.state.dict['order_by_dimension'], icon: "fullscreen", key: "size" }
        ]
      },
      showExitDialog: false,
      showSearch: false,
      imageDialog:false,
      dialogStart:0,
      delimiter:30,
      dialogHeight:100
    };
  },
  filters: {
    formatFileSize: function(size) {
      if (!Number.isFinite(size)) return "";

      var UNITS = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      var i = 0;
      do {
        size /= 1024;
        i++;
      } while (size > 1024);

      return Math.max(size, 0.1).toFixed(1) + " " + UNITS[i];
    },
    formatFileDate: function(date) {
      return moment(date, "X")
        .calendar()
        .toLowerCase();
    }
  },
  computed: {
  
    sortKey: {
      get: function() {
        return this.$store.state.sortKey;
      },
      set: function(key) {
        this.$store.commit("setSortKey", key);
      }
    },
    sortDirAsc: {
      get: function() {
        return this.$store.state.sortDirAsc;
      },
      set: function(dirAsc) {
        this.$store.commit("setSortDirAsc", dirAsc);
      }
    },
    onlyFolders:function(){
      return !this.items.some(elem=>elem.type!='dir')
    },
    images:function(){
      return this.items.map(item=>item.url)
    },
    captions:function(){
      return this.items.map(item=>item.name.split('.')[0])
    },
    items() {
      return this.$store.state.files
        .filter(item => {
          return item.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase() || "");
        })
        .sort((a, b) => {
          if(typeof(a[this.sortKey])=='string'){
            if (this.sortDirAsc) {
              if (a[this.sortKey].toLowerCase() < b[this.sortKey].toLowerCase())
                return -1;
              if (a[this.sortKey].toLowerCase() > b[this.sortKey].toLowerCase())
                return 1;
            }
            else {
              if (a[this.sortKey].toLowerCase() < b[this.sortKey].toLowerCase())
                return 1;
              if (a[this.sortKey].toLowerCase() > b[this.sortKey].toLowerCase())
                return -1;
            }
          }
          else{
            if (this.sortDirAsc){
              if (a[this.sortKey] < b[this.sortKey])
                return -1;
              if (a[this.sortKey] > b[this.sortKey])
                return 1;
            }
            else {
              if (a[this.sortKey] < b[this.sortKey])
                return 1;
              if (a[this.sortKey] > b[this.sortKey])
                return -1;
            }
          }
          return 0;
        })
        .slice(0,this.delimiter)
    },
    pathItems() {
      var pathItems = [];
      var path = "";
      if (this.$store.state.path.length > 1)
        this.$store.state.path
          .split("/")
          .slice(1)
          //eslint-disable-next-line
          .forEach((folder, i) => {
            path += "/" + folder;
            pathItems.push({ name: folder, path: path });
          });
      pathItems.unshift({ icon: "home", name: "", path: "/" });
      pathItems[pathItems.length - 1].disabled = true;

      return pathItems;
    },
    currFolder() {
      return this.pathItems[this.pathItems.length - 1];
    },
    prevFolder() {
      if (this.pathItems.length > 1)
        return this.pathItems[this.pathItems.length - 2];
      else return this.pathItems[this.pathItems.length - 1];
    },
    dialogStyle(){
      return 'width:100vw;height:'+this.dialogHeight+'px'
    }
  },
  methods: {
    log:function(msg){
      console.log(msg);
    },
    refresh() {
      this.loading = true;
      this.$store.dispatch("reloadDir", this.$store.state.path);
      this.loading= false
    },
    openItem(item,index) {
      if (item.type == "dir"){
        this.$router.push(
          (this.$store.state.path + "/" + item.name).replace("//", "/")
        );
        this.loading=true
        // this.$store.dispatch('listDir', (this.$store.state.path+"/"+item.name).replace("//", "/"))
        // .then(this.loading=false)
      }
      else{
        this.dialogStart=index
        this.imageDialog=true
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    updateSortKey(sortKey) {
      if (this.sortKey == sortKey) {
        this.sortDirAsc = !this.sortDirAsc;
      } else {
        this.sortDirAsc = true;
        this.sortKey = sortKey;
      }
      this.sorters.show = false;
    },
    getUrl(item){
      if(!('url' in item)){
        if(!('call' in item)){
          this.$set(item,'call',true)
          let path = (this.$store.state.path+"/"+item.name).replace("//", "/")
          if (item.type=='dir')
            path+="/cover.jpg"
          this.$store.dispatch('getUrl',path)
          .then((response) =>{
            this.$set(item,'url',response)
          })
        }
      }
      return item.url
    },
    scrollHandler($state=null){
      if(this.delimiter < this.$store.state.files.length){
        this.delimiter = this.delimiter+30 >= this.$store.state.files.length ? this.$store.state.files.length : this.delimiter+30
        if($state)
          $state.loaded()
      }
      else if($state)
        $state.complete()
      
    }
  },
  mounted: function() {
    moment.locale('it')
    this.loading = true;
    
    window.addEventListener('resize', ()=>{this.dialogHeight=window.innerHeight})
    this.dialogHeight=window.innerHeight

    if(this.$store.state.title=='')
      this.$store.dispatch('getTitle')

    this.$store.dispatch('listDir', (this.$route.path).replace("//", "/"))
    .then(()=>{
      this.loading = false
    })
    
  },
  watch:{
    imageDialog: (value)=>{
      document.getElementsByTagName('html')[0].style['overflowY'] = value ? 'hidden': 'scroll';
    },
    dialogStart: function(value){
      if(value >= this.delimiter-1)
        this.scrollHandler()
    }
  }
});
</script>

<style scoped>
#top_bar{
  border-radius: 0 !important;
}
.v-breadcrumbs a {
  text-decoration: none;
}
.disabled {
  color: grey;
  pointer-events: none;
}
.gallery-image{
  background-size: contain;
  background-position: center;
  aspect-ratio: 1/1;
}
</style>
